/* eslint-disable jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Pagination from "react-js-pagination";
import {
  findCandidatesForJobByStatus,
  findJobs,
  removeJobPosting,
} from "../actions/jobs";
import {
  Button,
  ButtonGroup,
  Col,
  Container, DropdownButton, Dropdown,
  Form,
  FormControl,
  Modal,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import jobFunctions from "../data/jobFunctions";
import { 
  downloadResume, 
  returnTextForArrayValue, 
  openResumeInTab 
} from "../utils/commons";
import industries from "../data/industries";
import moment from "moment";
import terms from "../data/terms";
import {
  acceptTermsForCandidate,
  inviteCandidate,
  rejectCandidate,
} from "../actions/candidates";
import { initPage } from "../actions/pages";
import Datetime from "react-datetime";
import countries from "../data/countries";
import states from "../data/states";
import { findLoggedEmployer } from "../actions/employers";
import Auth from "../utils/auth";
import { toast } from "react-toastify";
import CandidateStatus from "./CandidateStatus"

const SalaryDisplay = ({ from, to }) => {
  if (from || to) {
    return ` $${from / 1000 || ''}k - $${to / 1000 || ''}k`;
  }
  return "-";
}

const Status = Object.freeze({
  None: 0,
  Expired: 1,
  Draft: 2,
  Posted: 3,
});

function statusFromPath(path) {
  if (!path) return 0;
  return parseInt(path.split('/')[3], 10) || 0;
}

const StatusFilter = ({ active, onChange }) => (
  <DropdownButton
    id="employer-myjobs-filter-btns"
    title="Filter"
    className="float-right"
    style={{ marginRight: "10px" }}
  >
    <Dropdown.Item as="button" active={active === Status.None} onClick={() => onChange(Status.None)}>
      None
    </Dropdown.Item>
    <Dropdown.Item as="button" active={active === Status.Expired} onClick={() => onChange(Status.Expired)}>
      Expired
    </Dropdown.Item>
    <Dropdown.Item as="button" active={active === Status.Draft} onClick={() => onChange(Status.Draft)}>
      Draft
    </Dropdown.Item>
    <Dropdown.Item as="button" active={active === Status.Posted} onClick={() => onChange(Status.Posted)}>
      Posted
    </Dropdown.Item>
  </DropdownButton>
);

const StatusDisplay = ({ job }) => {
  if (job.draft) return "Draft";
  if (job.isDeleted) return "Deleted";
  if (job.isExpired) return "Expired";
  return "Posted";
}

class EmployerHomePage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    loggedEmployer: PropTypes.object.isRequired,
    jobs: PropTypes.array.isRequired,

    findLoggedEmployer: PropTypes.func.isRequired,
    findJob: PropTypes.func.isRequired,
    removeJobPosting: PropTypes.func.isRequired,
    acceptTermsForCandidate: PropTypes.func.isRequired,
    initPage: PropTypes.func.isRequired,
    findCandidatesForJobByStatus: PropTypes.func.isRequired,
    itemsCount: PropTypes.number,
  };
  timeout = null;
  state = {
    pageNr: 0,
    perPage: 20,
    search: "",
    openedRow: null,
    inviteModal: false,
    date1: new Date(),
    date2: new Date(),
    date3: new Date(),
    note: "",
    candidateId: null,
    status: 0,
  };

  constructor(props) {
    super(props);
    this.state.status = statusFromPath(props.history.location.pathname);
  }

  _onAddClick = () => this.props.history.push("/job/new");
  _purchasePost = () => this.props.history.push("/post/purchase");

  async componentDidMount() {
    await this.props.initPage("EmployerHomePage");
    const auth = Auth.getInstance();
    const userId = auth.getUserId();
    this.findJobs();
    this.props.findLoggedEmployer({ employerId: userId });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.status !== this.state.status) this.findJobs();
  }

  findJobs = () => {
    const { search, status } = this.state;
    if (search) {
      this.props.findJob({
        offset: this.state.pageNr,
        limit: this.state.perPage,
        search: this.state.search,
        byStatus: status,
      });
    } else {
      this.props.findJob({
        offset: this.state.pageNr,
        limit: this.state.perPage,
        byStatus: status,
      });
    }
  };
  pageChange = (pageNumber) => {
    const pageNr = pageNumber - 1;
    this.setState({ pageNr: pageNr }, () => {
      this.findJobs();
    });
  };
  search = (e) => {
    if (this.timeout) clearTimeout(this.timeout);
    this.setState(
      {
        search: e.target.value,
        pageNr: 0,
      },
      () =>
        (this.timeout = setTimeout(() => {
          this.findJobs();
        }, 900))
    );
  };
  changeNotes = (e) => {
    this.setState({
      note: e.target.value,
    });
  };
  openRow = (e, id) => {
    e.stopPropagation();
    this.setState({
      openedRow: id,
    });
  };
  closeRow = (e) => {
    e.stopPropagation();
    this.setState({
      openedRow: null,
    });
  };
  removeJob = async (e, id) => {
    e.stopPropagation();
    await this.props.removeJobPosting({ jobId: id });
  };

  inviteCandidate = async (e) => {
    e.stopPropagation();
    if (
      this.state.date1 === null &&
      this.state.date2 === null &&
      this.state.date3 === null &&
      this.state.note === ""
    ) {
      toast.error("You need to fill at least one date field or note field");
      return;
    }
    await this.props.inviteCandidate({
      candidateId: this.state.candidateId,
      dateSlot1: this.state.date1 || "",
      dateSlot2: this.state.date2 || "",
      dateSlot3: this.state.date3 || "",
      note: this.state.note,
    });
    toast.success(
      "The interview request you made has been successful, your account manager from RNR will contact you once the interview has been confirmed"
    );
    this.findJobs();
    this.onInviteModalHide();
  };
  openInviteModal = async (e, id) => {
    e.stopPropagation();
    this.setState({
      inviteModal: true,
      candidateId: id,
      date1: new Date(),
      date2: new Date(),
      date3: new Date(),
      note: "",
    });
  };
  onInviteModalHide = () => {
    this.setState({
      inviteModal: false,
      date1: new Date(),
      date2: new Date(),
      date3: new Date(),
      note: "",
      candidateId: null,
    });
  };
  onChangeDate = (val, date) => {
    this.setState({
      [date]: val,
    });
  };
  rejectCandidate = async (e, id) => {
    e.stopPropagation();
    await this.props.rejectCandidate({ candidateId: id });
    this.findJobs();
  };

  _showTermsModal = (candidateId) =>
    this.setState({ termsAgreement: 1, candidateIdToAcceptTerms: candidateId });

  _onTermsHide = () =>
    this.setState({ termsAgreement: 0, candidateIdToAcceptTerms: undefined });

  _agreeToTerms = async () => {
    await this.props.acceptTermsForCandidate({
      candidateId: this.state.candidateIdToAcceptTerms,
    });
    this.setState(
      { termsAgreement: 0, candidateIdToAcceptTerms: undefined },
      () => {
        this.findJobs();
      }
    );
  };
  _fetchCandidatesWithFilter = async (jobId, status) =>
    await this.props.findCandidatesForJobByStatus({ jobId, status });

  _onCandidateDetailsHide = () =>
    this.setState({
      showCandidateDetails: false,
      selectedCandidate: null,
      jobOfSelectedCandidate: null,
    });
  showCandidateDetails = (candidate, job) => {
    this.setState({
      showCandidateDetails: true,
      selectedCandidate: candidate,
      jobOfSelectedCandidate: job,
    });
  };
  pluralize(count) {
    if (count === 1) {
      return "";
    }
    return "s";
  }
  renderJobDetailsRow = (label, value) => {
    return (
      <p className="job-details-row" key={label}>
        <span className="span-label">{label}</span>: {value}
      </p>
    );
  };
  renderCandidateDetails(job) {
    const { selectedCandidate, jobOfSelectedCandidate } = this.state;
    return (
      <>
        <div
          className="candidates-filter-cards"
          style={{ marginLeft: "auto", marginRight: "auto", cursor: "pointer" }}
        >
          <div
            className={`filterableCard ${
              job.activeCandidateFilter === "all" ? "active" : ""
            }`}
            onClick={() => this._fetchCandidatesWithFilter(job.jobId, "all")}
          >
            <div className="text-center">
              {job.noOfCandidates} candidate{this.pluralize(job.noOfCandidates)}
            </div>
          </div>
          <div
            className={`filterableCard ${
              job.activeCandidateFilter === "selectedForInterview"
                ? "active"
                : ""
            }`}
            onClick={() =>
              this._fetchCandidatesWithFilter(job.jobId, "selectedForInterview")
            }
          >
            <div className="text-center">
              {job.noOfCandidatesSelectedForInterview} Selected for Interview
            </div>
          </div>
          <div
            className={`filterableCard ${
              job.activeCandidateFilter === "rejected" ? "active" : ""
            }`}
            onClick={() =>
              this._fetchCandidatesWithFilter(job.jobId, "rejected")
            }
          >
            <div className="text-center">
              {job.noOfRejectedCandidates} Rejected Candidate
              {this.pluralize(job.noOfRejectedCandidates)}
            </div>
          </div>
          <div
            className={`filterableCard ${
              job.activeCandidateFilter === "offersMade" ? "active" : ""
            }`}
            onClick={() =>
              this._fetchCandidatesWithFilter(job.jobId, "offersMade")
            }
          >
            <div className="text-center">
              {job.noOfOffersMadeForCandidates} Offer
              {this.pluralize(job.noOfOffersMadeForCandidates)} Made
            </div>
          </div>
          <div
            className={`filterableCard ${
              job.activeCandidateFilter === "offersAccepted" ? "active" : ""
            }`}
            onClick={() =>
              this._fetchCandidatesWithFilter(job.jobId, "offersAccepted")
            }
          >
            <div className="text-center">
              {job.noOfOffersAccepted} Offer
              {this.pluralize(job.noOfOffersAccepted)} Accepted
            </div>
          </div>
        </div>
        <Table>
          <thead>
            <tr>
              <th>Candidate</th>
              <th>Status</th>
              <th>Salary Range</th>
              <th>Date Submitted</th>
              <th className="actions-transparent">Actions</th>
            </tr>
          </thead>
          <tbody>
            {!job.candidates || job.candidates.length === 0 ? (
              <tr key="noCandidatesKey">
                <td colSpan={7} className="text-center">
                  <small>
                    {this.props.findingCandidatesForJob
                      ? "Loading..."
                      : "No candidates with specified filter"}
                  </small>
                </td>
              </tr>
            ) : (
              job.candidates.map((candidate) => (
                <tr key={candidate.candidateId}>
                  <td>
                    {candidate.firstName} {candidate.lastName}
                  </td>
                  <td>
                    <CandidateStatus candidate={candidate} />
                  </td>
                  <td>
                    {candidate.minSalary} - {candidate.maxSalary}
                  </td>
                  <td>
                    {moment(candidate.postDate).isValid()
                      ? (moment().diff(candidate.postDate, 'days') < 25)
                        ? moment(candidate.postDate).fromNow()
                        : "30+ days ago"
                      : ""}
                  </td>
                  <td className="text-center">
                    <div>
                      {!job.isDeleted &&
                      !candidate.candidateRejectedByEmployer ? (
                        <ButtonGroup className="btn-group-actions">
                          {candidate.employerAgreedOnLicenseTerms ? (
                            <>
                              <OverlayTrigger
                                placement="left"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip>View Details</Tooltip>}
                              >
                                <Button
                                  size="sm"
                                  variant="light"
                                  onClick={(e) =>
                                    this.showCandidateDetails(candidate, job)
                                  }
                                  className="material-icons"
                                >
                                  search
                                </Button>
                              </OverlayTrigger>
                              
                              {candidate.resume ? (
                                <>
                                  <OverlayTrigger
                                    placement="auto"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip>View Resume</Tooltip>}
                                    top
                                  >
                                    <Button
                                      size="sm"
                                      variant="primary"
                                      onClick={(e) => openResumeInTab(e, candidate)}
                                      className="material-icons"
                                    >
                                      launch
                                    </Button>
                                  </OverlayTrigger>

                                  <OverlayTrigger
                                    placement="auto"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip>Download Resume</Tooltip>}
                                    top
                                  >
                                    <Button
                                      size="sm"
                                      variant="primary"
                                      onClick={(e) => downloadResume(e, candidate)}
                                      className="material-icons"
                                    >
                                      attachment
                                    </Button>
                                  </OverlayTrigger>
                                </>
                              ) : null}{" "}
                            </>
                          ) : (
                            <OverlayTrigger
                              placement="left"
                              offsetParent={100}
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip>Accept Terms and Conditions</Tooltip>
                              }
                            >
                              <Button
                                size="sm"
                                variant="success"
                                onClick={(e) =>
                                  this._showTermsModal(candidate.candidateId)
                                }
                                className="material-icons"
                              >
                                check
                              </Button>
                            </OverlayTrigger>
                          )}
                          <OverlayTrigger
                            placement="auto"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>Reject Candidate</Tooltip>}
                          >
                            <Button
                              size="sm"
                              variant="danger"
                              onClick={(e) =>
                                this.rejectCandidate(e, candidate.candidateId)
                              }
                              className="material-icons"
                            >
                              cancel
                            </Button>
                          </OverlayTrigger>
                          {!candidate.candidateInvitedByEmployer &&
                            candidate.employerAgreedOnLicenseTerms ? (
                            <OverlayTrigger
                              placement="auto"
                              delay={{ show: 250, hide: 400 }}
                              overlay={<Tooltip>Invite Candidate</Tooltip>}
                              top
                            >
                              <Button
                                size="sm"
                                variant="primary"
                                onClick={(e) =>
                                  this.openInviteModal(
                                    e,
                                    candidate.candidateId
                                  )
                                }
                                className="material-icons"
                              >
                                perm_contact_calendar
                              </Button>
                            </OverlayTrigger>
                          ) : null}
                        </ButtonGroup>
                      ) : null}
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
        <Modal
          centered
          show={this.state.termsAgreement === 1}
          onHide={this._onTermsHide}
        >
          <Modal.Header closeButton>
            <Modal.Title>Terms and Conditions</Modal.Title>
          </Modal.Header>
          <Modal.Body className="terms-body">
            <div
              dangerouslySetInnerHTML={{ __html: terms.employerViewCandidate }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={this._onTermsHide}>
              Close
            </Button>
            <Button variant="primary" onClick={this._agreeToTerms}>
              I Agree
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          centered
          show={this.state.inviteModal}
          onHide={this.onInviteModalHide}
        >
          <Modal.Header closeButton>
            <Modal.Title>Schedule interview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Select upto three time slots for candidate to chose from or
              contact RNR to schedule an interview
            </p>
            <div className="schedule-row">
              <div>
                <Form.Label>Interview slot 1</Form.Label>
              </div>
              <Datetime
                onChange={(val) => this.onChangeDate(val, "date1")}
                value={this.state.date1}
                locale="en-CA"
              />
            </div>
            <div className="schedule-row">
              <div>
                <Form.Label>Interview slot 2</Form.Label>
              </div>
              <Datetime
                onChange={(val) => this.onChangeDate(val, "date2")}
                value={this.state.date2}
                locale="en-CA"
              />
            </div>
            <div className="schedule-row">
              <div>
                <Form.Label>Interview slot 3</Form.Label>
              </div>
              <Datetime
                onChange={(val) => this.onChangeDate(val, "date3")}
                value={this.state.date3}
                locale="en-CA"
              />
            </div>
            <div className="schedule-row">
              <Form.Label>Notes</Form.Label>
              <FormControl
                type="text"
                placeholder="Notes"
                value={this.state.note}
                onChange={(e) => this.changeNotes(e)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={this.onInviteModalHide}>
              Close
            </Button>
            <Button variant="primary" onClick={this.inviteCandidate}>
              Invite
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          centered
          show={this.state.showCandidateDetails}
          onHide={this._onCandidateDetailsHide}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.showCandidateDetails
                ? `Candidate | ${selectedCandidate.firstName} ${selectedCandidate.lastName}`
                : null}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedCandidate ? (
              <>
                <h5 className="h5-job-details">Candidate Details</h5>
                {this.renderJobDetailsRow(
                  "First Name",
                  selectedCandidate.firstName
                )}
                {this.renderJobDetailsRow(
                  "Last Name",
                  selectedCandidate.lastName
                )}
                {this.renderJobDetailsRow(
                  "Email Address",
                  selectedCandidate.email
                )}
                {this.renderJobDetailsRow(
                  "Phone",
                  selectedCandidate.phone
                )}
                {this.renderJobDetailsRow(
                  "City",
                  selectedCandidate.city
                )}
                {this.renderJobDetailsRow(
                  "Province/State",
                  returnTextForArrayValue(states, selectedCandidate.province)
                )}
                {this.renderJobDetailsRow(
                  "Country",
                  returnTextForArrayValue(countries, selectedCandidate.country)
                )}
                {this.renderJobDetailsRow(
                  "LinkedIn Profile",
                  selectedCandidate.linkedInProfile
                )}
                <hr />
                <h5 className="h5-job-details">Candidate Profile</h5>
                {this.renderJobDetailsRow(
                  "CPA Designated",
                  selectedCandidate.highestCollegeDegree
                )}
                {this.renderJobDetailsRow(
                  "Most Recent Job Title",
                  selectedCandidate.mostRecentJobTitle
                )}
                {this.renderJobDetailsRow(
                  "Current Employer",
                  selectedCandidate.currentEmployer
                )}
                {this.renderJobDetailsRow(
                  "Minimum Salary",
                  selectedCandidate.minSalary
                )}
                {this.renderJobDetailsRow(
                  "Maximum Salary",
                  selectedCandidate.maxSalary
                )}
                {this.renderJobDetailsRow(
                  "Note From Recruiter",
                  selectedCandidate.recruiterNote
                )}
                {this.renderJobDetailsRow(
                  "Note From Candidate",
                  selectedCandidate.candidateNote
                )}
              </>
            ) : (
              "No details"
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="marign-right-10"
              variant="danger"
              onClick={this._onCandidateDetailsHide}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  _onStatusFilterChange(status) {
    this.props.history.replace('/employer/home/' + status);
    this.setState({ status });
  }

  render() {
    const { loggedEmployer } = this.props;
    return (
      <>
        <Container fluid className="view-name-header">
          <Row>
            <Col sm="5" className="padding-none">
              <h4>{`Job Postings (you have ${
                loggedEmployer.purchasedJobs ? loggedEmployer.purchasedJobs : 0
              } purchased posts left)`}</h4>
            </Col>
            <Col sm="4" className="padding-none">
              <StatusFilter active={this.state.status} onChange={this._onStatusFilterChange.bind(this)} />
              <Button
                className="float-right"
                style={{ marginRight: "10px" }}
                onClick={this._onAddClick}
              >
                Add new
              </Button>
              <Button
                className="float-right"
                style={{ marginRight: "10px" }}
                onClick={this._purchasePost}
              >
                Purchase a post
              </Button>
            </Col>
            <Col sm="3" className="padding-none">
              <FormControl
                type="text"
                placeholder="Search"
                value={this.state.search}
                onChange={this.search}
              />
            </Col>
          </Row>
        </Container>
        <div className="table-wrapper">
          <Table style={{ padding: "10px 40px" }}>
            Found {this.props.itemsCount} job postings
            <tbody>
              {this.props.jobs.map((job) => (
                <React.Fragment key={job.jobId}>
                  <Button variant="job-listing"
                    onClick={!job.draft ? (e) => this.state.openedRow !== job.jobId
                      ? this.openRow(e, job.jobId)
                      : this.closeRow(e)
                      : null}>
                    <div className="job-listing-details">
                      <div className="job-listing-details-left">
                        <p className="company-name"> {job.companyName} </p>
                        <p className="job-name"> {job.jobTitle} </p>
                        <p> {returnTextForArrayValue(jobFunctions, job.jobFunction)} | {returnTextForArrayValue(industries, job.industry)} | <SalaryDisplay from={job.salaryFrom} to={job.salaryTo} /></p>
                      </div>
                      <div className="job-listing-details-right">
                        <div className="earnings-postdate">
                          <p className="recruiter-earnings">
                            <StatusDisplay job={job}/>
                          </p>
                          <p className="post-date">
                            {moment(job.postDate).isValid()
                              ? (moment().diff(job.postDate, 'days') < 25)
                                ? moment(job.postDate).fromNow()
                                : "30+ days ago"
                              : ""}
                          </p>
                        </div>
                        <div>
                          <ButtonGroup className="btn-group-actions">
                          {!job.isDeleted ? (
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={<Tooltip>Delete</Tooltip>}
                            >
                              <Button
                                size="sm"
                                variant="danger"
                                onClick={(e) => this.removeJob(e, job.jobId)}
                                className="material-icons"
                              >
                                delete
                              </Button>
                            </OverlayTrigger>
                          ) : null}
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>Edit</Tooltip>}
                          >
                            <Button
                              size="sm"
                              variant="primary"
                              onClick={() => {
                                this.props.history.push(`/job/${job.jobId}`);
                              }}
                              className="material-icons"
                            >
                              edit
                            </Button>
                          </OverlayTrigger>
                        </ButtonGroup>
                        </div>
                      </div>
                    </div>
                  </Button>
                  {!job.draft && this.state.openedRow === job.jobId ? (
                    <tr>
                      <td className="candidate-td">
                        {this.renderCandidateDetails(job)}
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </div>
        <Pagination
          activePage={this.state.pageNr + 1}
          itemsCountPerPage={this.state.perPage}
          totalItemsCount={this.props.itemsCount}
          pageRangeDisplayed={5}
          onChange={this.pageChange}
        />
      </>
    );
  }
}

export default connect(
  (state) => ({
    jobs: state.jobs.jobs,
    findingCandidatesForJob: state.jobs.findingCandidatesForJob,
    loggedEmployer: state.employers.loggedEmployer,
    itemsCount: state.jobs.itemsCount,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        findJob: findJobs,
        removeJobPosting: removeJobPosting,
        acceptTermsForCandidate: acceptTermsForCandidate,
        rejectCandidate: rejectCandidate,
        inviteCandidate: inviteCandidate,
        initPage: initPage,
        findCandidatesForJobByStatus: findCandidatesForJobByStatus,
        findLoggedEmployer: findLoggedEmployer,
      },
      dispatch
    )
)(EmployerHomePage);
