import React, { Component } from "react";
import { Route, Switch, Link } from "react-router-dom";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HomePage from "./components/HomePage";
import LoginPage from "./Auth/LoginPage";
import VerifyRecruiterPage from "./components/VerifyRecruiterPage";
import VerifyEmployerPage from "./components/VerifyEmployerPage";
import PrivateRoute from "./components/PrivateRoute";
import EmployerHomePage from "./components/EmployerHomePage";
import JobPostPlansPage from "./components/JobPostPlansPage";
import SignUpEmployerSuccessPage from "./components/SignUpEmployerSuccessPage";
import SignUpRecruiterSuccessPage from "./components/SignUpRecruiterSuccessPage";
import JobDetailsPage from "./components/JobDetailsPage";
import ListOfRecruiters from "./components/ListOfRecruiters";
import RecruiterHomePage from "./components/pages/RecruiterHomePage";
import ListOfEmployers from "./components/ListOfEmployers";
import ListOfUsers from "./components/ListOfUsers";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Auth from "./utils/auth";
import SessionManager from "./Auth/SessionManager";
import { logout } from "./Auth/actions";
import { bindActionCreators } from "redux";
import CandidateDetailsPage from "./components/pages/CandidateDetailsPage";
import VerifyCandidatePage from "./components/VerifyCandidatePage";
import ChatPage from "./components/ChatPage";
import ListOfCandidatesForAdmin from "./components/ListOfCandidatesForAdmin";
import { withRouter } from "react-router";
import axios from "axios";
import ChangePasswordLoggedPage from "./components/ChangePasswordLoggedPage";
import PasswordResetPage from "./components/PasswordResetPage";
import ResetPasswordGuidPage from "./components/ResetPasswordGuidPage";
import ListOfCodes from "./components/ListOfCodes";
import ListOfRetailTerms from "./components/ListOfRetailTermsPage";
import ListOfInterviewReq from "./components/InterviewTimesPage";
import ListOfCandidates from "./components/ListOfCandidates";
import SignUpReferralPartnerSuccessPage from './components/SignUpReferralPartnerSuccessPage';
import SignUpPage from './components/SignUpPage';
import CheckCandidatesPage from './components/pages/CheckCandidatesPage';

import logo from "./assets/img/logo.png"

const URLS_REMOVED_FROM_LOADER = [
  "/api/v1/conversations/get",
  "/api/v1/conversations/sendMessage",
];

const MyJobsNavLink = ({ pathname }) => (
  <Nav.Link
    active={pathname.startsWith("/employer/home")}
    as={Link}
    to={'/employer/home'}
  >
    My Jobs
  </Nav.Link>
);

const JobsNavLink = ({ pathname }) => (
  <Nav.Link
    active={pathname === "/recruiter/home"}
    as={Link}
    to={'/recruiter/home'}
  >
    Jobs
  </Nav.Link>
);

const RefJobsNavLink = ({ pathname }) => (
    <Nav.Link
        active={pathname === "/referralpartner/home"}
        as={Link}
        to={'/referralpartner/home'}
    >
        Jobs
    </Nav.Link>
);

const ChatNavLink = ({ pathname }) => (
  <Nav.Link
    as={Link}
    to="/chat"
    active={pathname === "/chat"}
  >
    Chat
  </Nav.Link>
);

const ComingSoonNavLink = ({ pathname }) => (
  <Nav.Link
    as={Link}
    to={"https://inforecruitnrefer.ca/coming-soon"}
    onClick={() => window.open("https://inforecruitnrefer.ca/coming-soon", "_blank")}
  >
    Coming Soon
  </Nav.Link>
);

const CandidatesNavLink = ({ pathname }) => (
  <Nav.Link
    as={Link}
    to="/candidates"
    active={pathname.startsWith("/candidates")}
  >
    Candidates
  </Nav.Link>
);

const InterviewsNavLink = ({ pathname }) => (
  <Nav.Link
    as={Link}
    to="/interviewReq"
    active={pathname === "/interviewReq"}
  >
    Interviews
  </Nav.Link>
);

const CampaignsNavLink = ({ pathname }) => (
  <Nav.Link
    as={Link}
    to={"https://inforecruitnrefer.ca/marketing-campaigns"}
    onClick={() => window.open("https://inforecruitnrefer.ca/marketing-campaigns", "_blank")}
  >
    Campaigns
  </Nav.Link>
);

const RecruiterGettingStartedNavLink = ({ pathname }) => (
  <Nav.Link
    as={Link}
    to={"https://inforecruitnrefer.ca/how-to-start"}
    onClick={() => window.open("https://inforecruitnrefer.ca/how-to-start", "_blank")}
  >
    Getting Started
  </Nav.Link>
);

const EmployerGettingStartedNavLink = ({ pathname }) => (
  <Nav.Link
    as={Link}
    to={"https://inforecruitnrefer.ca/how-to-start-clients"}
    onClick={() => window.open("https://inforecruitnrefer.ca/how-to-start-clients", "_blank")}
  >
    Getting Started
  </Nav.Link>
);

const CheckCandidatesNavLink = ({ pathname }) => (
  <Nav.Link
    active={pathname.startsWith("/check/candidate")}
    as={Link}
    to="/check/candidate"
  >
    Check Candidates
  </Nav.Link>
);

const ScopedNav = ({ scope, pathname }) => {
  switch (scope) {
    case 'admin':
      return (
        <Nav>
          <MyJobsNavLink pathname={pathname} />
          <JobsNavLink pathname={pathname} />
          <Nav.Link
            active={pathname === "/recruiters"}
            as={Link}
            to="/recruiters"
          >
            Recruiters
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/employers"
            active={pathname === "/employers"}
          >
            Employers
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/users"
            active={pathname === "/users"}
          >
            Users
          </Nav.Link>
          <CandidatesNavLink pathname={pathname} />
          <ChatNavLink pathname={pathname} />
          <Nav.Link
            as={Link}
            to="/codes"
            active={pathname === "/code"}
          >
            Codes
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/retailTerms"
            active={pathname === "/retailTerms"}
          >
            Recruiter Terms
          </Nav.Link>
          <InterviewsNavLink pathname={pathname} />
          <ComingSoonNavLink pathname={pathname} />
          <CampaignsNavLink pathname={pathname} />
          <CheckCandidatesNavLink pathname={pathname} />
        </Nav>
      );
    case 'employer':
      return (
        <Nav>
          <EmployerGettingStartedNavLink pathname={pathname} />
          <MyJobsNavLink pathname={pathname} />
          <JobsNavLink pathname={pathname} />
          <ChatNavLink pathname={pathname} />
          <ComingSoonNavLink pathname={pathname} />
        </Nav>
      );
    case 'recruiter':
      return (
        <Nav>
          <RecruiterGettingStartedNavLink pathname={pathname} />
          <JobsNavLink pathname={pathname} />
          <CandidatesNavLink pathname={pathname} />
          <InterviewsNavLink pathname={pathname} />
          <ChatNavLink pathname={pathname} />
          <ComingSoonNavLink pathname={pathname} />
          <CampaignsNavLink pathname={pathname} />
        </Nav>
      );
      case 'referralpartner':
          return (
              <Nav>
                  <RecruiterGettingStartedNavLink pathname={pathname} />
                  <RefJobsNavLink pathname={pathname} />
                  <CandidatesNavLink pathname={pathname} />
                  <InterviewsNavLink pathname={pathname} />
                  <ChatNavLink pathname={pathname} />
                  <ComingSoonNavLink pathname={pathname} />
                  <CampaignsNavLink pathname={pathname} />
              </Nav>
          );
    default:
      return null;
  }
};

const Menu = ({ visible, userScope, pathname, userName, onLogoutClick }) => {
  if (!visible) return null;
  return (
    <Navbar variant="dark">
      <Navbar.Brand><img alt="" src={logo} /></Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <ScopedNav scope={userScope} pathname={pathname} />
      </Navbar.Collapse>
      <div style={{ marginLeft: "auto" }}>
        <NavDropdown title="" id="basic-nav-dropdown">
          <NavDropdown.Item as={Link} to="/changePassword">
            Change Password
          </NavDropdown.Item>
          <NavDropdown.Item onClick={onLogoutClick}>
            Logout
          </NavDropdown.Item>
        </NavDropdown>
      </div>
      <span className="nav-username">{userName}</span>
    </Navbar>
  );
}

const Loader = ({ visible }) => {
  if (!visible) return null;
  return (
    <div className="loader">
      <div className="lds-roller">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

class App extends Component {
  state = {
    isLoaderVisible: false,
  };
  timeout = null;
  _auth = null;

  constructor(props) {
    super(props);
    this._auth = Auth.getInstance();
    if (props.token) this._auth.authenticate(props.token);
  }

  componentDidMount() {
    axios.interceptors.request.use(
      (config) => {
        if (!URLS_REMOVED_FROM_LOADER.includes(config.url)) {
          if (this.timeout) clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            this.setState({
              isLoaderVisible: true,
            });
          }, 100);
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    axios.interceptors.response.use(
      (response) => {
        if (this.timeout) clearTimeout(this.timeout);
        this.setState({
          isLoaderVisible: false,
        });
        return response;
      },
      (error) => {
        if (this.timeout) clearTimeout(this.timeout);
        this.setState({
          isLoaderVisible: false,
        });
        return Promise.reject(error);
      }
    );
  }

  logout = async () => {
    await this.props.logout(this.props.token);
    this._auth.logout();
    window.location.reload();
  };

  render() {
    const auth = this._auth;
    const isLoggedIn = auth.isAuthenticated();
    const pathsWithOutMenu = ["/sign-up", "verify/"];
    const userScope = auth.getScope();
    const isAdmin = userScope === "admin";
    const userName = auth.getUserName();
    const pathname = this.props.location.pathname;
    const showMenu = isLoggedIn && !pathsWithOutMenu.filter((x) => pathname.includes(x)).length;
    return (
      <React.Fragment>
        <Loader visible={this.state.isLoaderVisible} />
        <SessionManager />
        <ToastContainer autoClose={30000} position="top-center" />
        <Menu
          visible={showMenu}
          userScope={userScope}
          userName={userName}
          pathname={pathname}
          onLogoutClick={this.logout}
        />
        <div className="view-container bg-light">
          <div className="view-content">
            <Switch>
              <Route exact path="/login" component={LoginPage} />
              <Route exact path="/sign-up" component={SignUpPage} />
              <Route exact path="/employer/sign-up/success" component={SignUpEmployerSuccessPage} />
              <Route exact path="/recruiter/sign-up/success" component={SignUpRecruiterSuccessPage} />
              <Route exact path="/referralpartner/sign-up/success" component={SignUpReferralPartnerSuccessPage} />
              <Route exact path="/employer/verify/:employerId" component={VerifyEmployerPage} />
              <Route exact path="/recruiter/verify/:recruiterId" component={VerifyRecruiterPage} />
              <Route exact path="/referralpartner/verify/:recruiterId" component={VerifyRecruiterPage} />
              <Route
                exact
                path="/candidate/verify/:candidateId"
                component={VerifyCandidatePage}
              />
              <Route
                exact
                path="/password-reset"
                component={PasswordResetPage}
              />
              <Route
                exact
                path="/resetPasswordGuid/:guid"
                component={ResetPasswordGuidPage}
              />
              <PrivateRoute
                auth={auth}
                exact
                path="/check/candidate"
                component={CheckCandidatesPage}
                scopes={["admin"]}
              />
              <PrivateRoute
                auth={auth}
                path="/employer/home"
                scopes={["employer"]}
                component={EmployerHomePage}
              />
              <PrivateRoute
                auth={auth}
                exact
                path="/post/purchase"
                scopes={["employer"]}
                component={JobPostPlansPage}
              />
              <PrivateRoute
                auth={auth}
                exact
                path="/recruiter/home"
                scopes={["recruiter", "employer", "referralpartner"]}
                component={RecruiterHomePage}
              />
              <PrivateRoute
                auth={auth}
                exact
                path="/referralpartner/home"
                scopes={["recruiter", "employer", "referralpartner"]}
                component={RecruiterHomePage}
              />
              <PrivateRoute
                auth={auth}
                exact
                path="/job/:jobId"
                scopes={["employer"]}
                component={JobDetailsPage}
              />
              <PrivateRoute
                auth={auth}
                exact
                path="/job/new"
                scopes={["employer"]}
                component={JobDetailsPage}
              />
              <PrivateRoute
                auth={auth}
                exact
                path="/candidate/:jobId/:candidateId"
                scopes={["recruiter", "referralpartner"]}
                component={CandidateDetailsPage}
              />
              <PrivateRoute
                auth={auth}
                exact
                path="/recruiters"
                component={ListOfRecruiters}
              />
              <PrivateRoute
                auth={auth}
                exact
                path="/employers"
                component={ListOfEmployers}
              />
              <PrivateRoute
                auth={auth}
                exact
                path="/users"
                component={ListOfUsers}
              />
              <PrivateRoute
                auth={auth}
                exact
                path="/chat"
                scopes={["recruiter", "employer", "referralpartner"]}
                component={ChatPage}
              />
              <PrivateRoute
                auth={auth}
                exact
                scopes={["recruiter", "employer", "referralpartner"]}
                path="/chat/:conversationId"
                component={ChatPage}
              />
              <PrivateRoute
                auth={auth}
                exact
                path="/codes"
                component={ListOfCodes}
              />
              <PrivateRoute
                auth={auth}
                exact
                path="/retailTerms"
                component={ListOfRetailTerms}
              />
              <PrivateRoute
                auth={auth}
                exact
                path="/interviewReq"
                component={ListOfInterviewReq}
                scopes={["admin", "recruiter", "referralpartner"]}
              />
              <PrivateRoute
                auth={auth}
                path="/candidates"
                component={isAdmin ? ListOfCandidatesForAdmin : ListOfCandidates}
                scopes={["admin", "recruiter", "referralpartner"]}
              />
              <PrivateRoute
                auth={auth}
                exact
                path="/changePassword"
                component={ChangePasswordLoggedPage}
                scopes={["recruiter", "employer", "referralpartner"]}
              />
              <PrivateRoute
                auth={auth}
                scopes={["employer", "recruiter", "referralpartner"]}
                component={HomePage}
              />
            </Switch>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

App.propTypes = {
  /* state */
  token: PropTypes.object,
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  logout: bindActionCreators(logout, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
