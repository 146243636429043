import axios from "axios";
import auth from "./auth";

import { toast } from "react-toastify";

const VALID_EXTENSIONS_TO_OPEN_IN_TAB = ['pdf'];

export const returnTextForArrayValue = (array, value) => {
  const val = array.find((x) => x.value === value);
  return typeof val !== "undefined" ? val.text || "" : "";
};

export function wait(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function formatedDate(date) {
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  return date.toLocaleDateString('en-CA', options);
}

function extname(path) {
  const dotIndex = path.lastIndexOf('.');
  if (dotIndex < 0) {
    return '';
  }
  return path.substring(dotIndex + 1);
}

function isValidExtension(ext) {
  return !!VALID_EXTENSIONS_TO_OPEN_IN_TAB.find(
    (s) => s === ext.toLocaleLowerCase()
  );
}

async function fetchResumeFile(fileName) {
  return (await axios
    .get(`${process.env.PUBLIC_URL}/api/v1/files/download`, {
      responseType: "blob",
      params: { fileName },
      headers: {
        Authorization: "Bearer " + auth.getInstance().getAccessToken(),
      },
    }));
}

export async function openResumeInTab(e, candidate) {
  e.stopPropagation();

  const { resume: fileName } = candidate;

  const ext = extname(fileName);
  if (!isValidExtension(ext)) {
    toast.error(`Viewing '.${ext}' files is not supported`);
    return;
  }

  await fetchResumeFile(fileName).then((response) => {
    const url = window.URL.createObjectURL(
      new File([response.data], fileName, {type: "application/pdf"})
    );
    window.open(url, '_blank');
  });  
}

export async function downloadResume(e, candidate) {
  e.stopPropagation();

  const {
    firstName, 
    lastName, 
    submittedAt,
    resume: fileName
  } = candidate;

  const ext = extname(fileName);
  const downloadedFileName = `${firstName.trim()}_${lastName.trim()}_resume_${formatedDate(new Date(submittedAt || new Date()))}.${ext}`;

  await fetchResumeFile(fileName).then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/octet-stream" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", downloadedFileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
  });
}

export async function startConversation(jobId, history, e) {
  e.stopPropagation();
  const createResult = await axios.post(
    `${process.env.PUBLIC_URL}/api/v1/conversations/createConversation`,
    {
      jobId,
    },
    {
      headers: {
        Authorization: "Bearer " + auth.getInstance().getAccessToken(),
      },
    }
  );
  history.push(`/chat/${createResult.data.conversationId}`);
}
