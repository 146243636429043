import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from 'axios'
import Autocomplete from 'react-autocomplete'
import {Alert, Button, Card, Col, Form} from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import 'tinymce/plugins/link';
import 'tinymce/icons/default';
import { toast } from "react-toastify";
import {
  validateName,
  validatePresent,
  validateNumber,
  validateState,
  isStateRequired,
} from "../utils/validators";
import jobFunctions from "../data/jobFunctions";
import industries from "../data/industries";
import countries from "../data/countries";
import states from "../data/states";
import jobTypes from "../data/jobTypes";
import {findJob, saveJobPostingDraft, saveJobPosting, saveJobExclusiveTo} from "../actions/jobs";
import { initPage } from "../actions/pages";
import { findLoggedEmployer } from "../actions/employers";
import Auth from "../utils/auth";
import Select from "react-select";
import {authorizationHeaders} from "../utils/api"

class AsyncSelect extends Component {
  state = {
    options: [],
    isLoading: true,
    page: 1,
    inputValue: '',
  };

  componentDidMount() {
    this.loadOptions(this.state.inputValue);
  }

  loadOptions = (inputValue, page = 1) => this.setState({
    inputValue,
    page,
    isLoading: true,
  }, () => {
    this.props.loadOptions(inputValue, page)
      .then(loadedOptions => {
        const options = page > 1 ? this.state.options : [];
        this.setState({
          options: options.concat(loadedOptions),
          isLoading: false,
        });
      });
  });

  onInputChange = (inputValue) => {
    this.loadOptions(inputValue);
  };

  onMenuScrollToBottom = (e) => {
    const { inputValue, page } = this.state;
    this.loadOptions(inputValue, page + 1);
  };

  render() {
    const { loadOptions, ...props } = this.props;
    const { options, isLoading } = this.state;
    return (
      <Select
        {...props}
        loadOptions={this.loadOptions}
        options={options}
        isLoading={isLoading}
        onInputChange={this.onInputChange}
        onMenuScrollToBottom={this.onMenuScrollToBottom}
      />
    )
  }
}

const autoCompleteMenuStyle = {
  position: 'fixed',
  zIndex: 1000,
  border: 'solid 1px #777777',
  padding: '5px 10px',
  background: 'white',
  overflow: 'auto'
}

const jobDetailsForm = {
  title: "Job Posting",
  sections: [
    {
      title: "Job Details",
      rows: [
        [
          {
            label: "Job Title",
            name: "jobTitle",
            type: "text",
            value: "lol",
            validator: validateName,
          },
          {
            label: "Job Function",
            name: "jobFunction",
            type: "select",
            validator: validatePresent,
            options: jobFunctions,
          },
        ],
        [
          {
            label: "Industry",
            name: "industry",
            type: "select",
            validator: validatePresent,
            options: industries,
          },
          {
            label: "Company Name",
            name: "companyName",
            type: "text",
            validator: validateName,
          },
        ],
        [
          {
            label: "Company Description",
            name: "companyDescription",
            type: "text",
            validator: validatePresent,
          },
          {
            label: "Reporting To",
            name: "reportingTo",
            type: "text",
            validator: validatePresent,
          },
        ],
        [
          {
            label: "Team Size To Manage",
            name: "teamSizeToManage",
            type: "number",
            validator: validateNumber,
          },
          {
            label: "Number of days that this posting will be valid for",
            name: "expiresAfter",
            type: "number",
            validator: validateNumber,
          },
        ],
      ],
    },
    {
      title: "Admin Only",
      isVisible: () => Auth.getInstance().getScope() === 'admin',
      rows: [
        [
          {
            label: "Exclusive To",
            name: "exclusiveTo",
            type: "custom",
            required: false,
            render: (self, { value }) => (
              <AsyncSelect
                isMulti
                loadOptions={self._findRecruiters}
                value={value}
                onChange={(values) => self.setState({ exclusiveTo: values, isExclusiveDirty: true })}
              />
            )
          }
        ]
      ]
    },
    {
      title: "Job Location",
      rows: [
        [
          {
            label: "Country",
            name: "country",
            type: "select",
            validator: validatePresent,
            options: countries,
          },
          {
            label: "Province/State",
            name: "state",
            type: "select",
            validator: validateState,
            options: state => state.country
              ? states.filter(x => x.country === state.country)
              : states,
            required: state => isStateRequired(state.country)
          },
        ],
        [
          {
            label: "City",
            name: "city",
            type: "autocomplete",
            validator: validatePresent,
          },
          {
            label: "Other Location",
            name: "otherLocation",
            type: "text",
            required: false,
          },
        ],
        [
          {
            label: "Location Type",
            name: "locationType",
            type: "radio",
            validator: validatePresent,
            options: [
              { value: "head-office", text: "Head Office" },
              { value: "from-home", text: "Work From Home" },
            ],
          },
          {
            label: "Candidate Relocation",
            name: "candidateRelocation",
            type: "radio",
            validator: validatePresent,
            options: [
              { value: "yes", text: "Yes" },
              { value: "no", text: "No" },
            ],
          },
        ],
      ],
    },
    {
      rows: [
        [
          {
            label: "Job Description",
            name: "jobDescription",
            type: "editor",
            required: false,
            colSize: 12,
          },
        ],
      ],
    },
    {
      title: "Candidate Skills and Experience",
      rows: [
        [
          {
            label: "Skills - Must Have",
            name: "mustHave",
            type: "textarea",
            instructions: "One per line",
            placeholder: "1. Skill 1\n2. Skill 2\n...",
            required: false,
            colSize: 12,
          },
        ],
        [
          {
            label: "Ideal Companies to Recruit From",
            name: "skillsConsideredBonus",
            type: "textarea",
            instructions: "Ideal companies for recruiters to source from",
            placeholder: "1. Company 1\n2. Company 2\n...",
            required: false,
            colSize: 12,
          },
        ],
      ],
    },
    {
      title: "Compensation",
      rows: [
        [
          {
            label: "Job Type",
            name: "jobType",
            type: "radio",
            options: jobTypes,
            colSize: 12,
          },
        ],
        [
          {
            label: "Salary",
            name: "salaryDisclosed",
            type: "checkbox",
            text: "Disclosed",
            colSize: 4,
            required: true,
          },
          {
            label: "From",
            name: "salaryFrom",
            type: "number",
            step: 1000,
            required: false,
            colSize: 4,
            validator: (value, state) => state.salaryDisclosed && !value && "must be present",
          },
          {
            label: "To",
            name: "salaryTo",
            type: "number",
            step: 1000,
            required: false,
            colSize: 4,
            validator: (value, state) => state.salaryDisclosed && !value && "must be present",
          },
        ],
        [
          {
            label: "Bonus Payment",
            name: "bonusPayment",
            type: "text",
            required: false,
          },
        ],
      ],
    },
    {
      title: "Pre-Screening Questions",
      rows: [
        [
          {
            label: "Questions",
            name: "questions",
            type: "textarea",
            instructions: "One per line",
            placeholder: "Question 1?\nQuestion 2?\n...",
            required: false,
            colSize: 12,
          },
        ],
      ],
    },
    {
      title: "Mandatory Details",
      rows: [
        [
          {
            label: "Number Of Positions",
            name: "numberOfPositions",
            type: "number",
            validator: validateNumber,
          },
          {
            label: "Hiring Status",
            name: "commissionPayment",
            type: "text",
          },
        ],
        [
          {
            label: "Culture, Perks and Benefits",
            name: "culturePerksAndBenefits",
            type: "editor",
            required: false,
            colSize: 12,
          },
        ],
      ],
    },
  ],
};

class JobDetailsPage extends Component {
  state = {
    country: '',
    state: '',
    city: '',
    // JD: fields added below need to be extracted from what is submitted
    cities: [],
    validationErrors: {},
    isDirty: false,
    exclusiveTo: null,
    isExclusiveDirty: false,
  };

  draftSaveInterval = null;

  _goBack = () => this.props.history.goBack();

  _renderForm({ title, sections, onSubmit, onCancel }) {
    return (
      <Form onSubmit={onSubmit} name="job-form">
        <Card>
          <Card.Header className="bg-white">{title}</Card.Header>
          <Card.Body>
            {this._renderFormError()}
            {sections.map((section, idx) => this._renderSection(section, idx))}
          </Card.Body>
          <Card.Footer className="text-right bg-white">
            <Button variant="danger" className="ml-2" onClick={onCancel}>
              Cancel
            </Button>
            {(Object.entries(this.props.job).length === 0 &&
              this.props.job.constructor === Object) ||
            (this.props.job && this.props.job.draft === true) ? (
              <Button className="ml-2" onClick={this._saveDraft}>
                Save as draft
              </Button>
            ) : null}
            {!this.props.job.isDeleted && !this.props.job.isExpired ? (
              <Button className="ml-2" type="submit">
                Send
              </Button>
            ) : (
              <Button className="ml-2" type="submit">
                Repost
              </Button>
            )}
          </Card.Footer>
        </Card>
      </Form>
    );
  }

  _renderFormError() {
    if (!this.props.formJobError) return null;
    return <Alert variant="danger">{this.props.formJobError}</Alert>;
  }

  _renderSection(section, idx) {
    if (typeof section.isVisible === 'function' && !section.isVisible()) return null;
    return (
      <React.Fragment key={idx}>
        {idx > 0 && <hr />}
        <Form.Row className="mb-2">
          <Col>
            <h5>{section.title}</h5>
          </Col>
        </Form.Row>
        {section.rows.map((row, index) => {
          return (
            <Form.Row key={index} className="mb-2">
              {row.map((field) => this._renderField(field))}
            </Form.Row>
          );
        })}
      </React.Fragment>
    );
  }

  _validateField = (validator) => (e) => {
    this.setState({
      validationErrors: {
        ...this.state.validationErrors,
        [e.target.name]: validator(e.target.value, this.state),
      },
    });
  };

  _onChange = (e) => {
    let extra = {}, cb = function() {}
    const inputName = e.target.name
    if (inputName === "country") {
      extra = { city: "", state: "", cities: [] }
    } else if (inputName === "state") {
      extra = { city: "" }
      cb = this._fetchCities
    }
    let value;
    switch (e.target.type) {
      case "number":
        value = Number(e.target.value);
        break;
      case "checkbox":
        value = e.target.checked;
        break;
      default:
        value = e.target.value;
    }
    const { [inputName]: previous, isDirty } = this.state;
    this.setState({
      [inputName]: value,
      isDirty: isDirty || previous !== value,
      ...extra
    }, cb);
  };

  _fetchCities = async(e) => {
    const {data} = await axios.get(
      `${process.env.PUBLIC_URL}/api/v1/data/cities`,
      {
        params: {state: this.state.state}
      }
    );
    this.setState({
      cities: data.map(x => ({
        value: x.name, //DO not use id
        text: x.name
      }))
    })
  }

  _renderField({
    label,
    name,
    validator,
    type,
    instructions,
    placeholder,
    required = true,
    colSize = 6,
    ...extra
  }) {
    const isRequired = typeof required === 'function' ? required(this.state) : required;
    const validationError = this.state.validationErrors[name];
    const props = {
      name,
      value: this.state[name] || "",
      isInvalid: !!validationError,
      onChange: this._onChange,
      onBlur: validator ? this._validateField(validator) : undefined,
      placeholder,
    };
    return (
      <Col sm={colSize} key={name}>
        <div>
          <Form.Label>
            {label}{" "}
            {isRequired ? <span className="required-field">*</span> : null}
          </Form.Label>
          {instructions && (
            <small className="font-italic d-block">{instructions}</small>
          )}
        </div>
        <div>
          {this._renderControl(type, props, extra, validationError)}
          <Form.Control.Feedback type="invalid">
            {validationError}
          </Form.Control.Feedback>
        </div>
      </Col>
    );
  }

  _renderControl(type, props, extra, validationError) {
    switch (type) {
      case "text":
        return <Form.Control type="text" {...props} />;
      case "number":
        return <Form.Control type="number" {...props} {...extra} />;
      case "textarea":
        return <Form.Control style={{whiteSpace: "pre-line"}} as="textarea" rows={3} {...props} />;
      case "select": {
        const { options } = extra;
        let optionsArray = typeof options === 'function' ? options(this.state) : options;
        if (props.name === "city") {
          optionsArray = this.state.cities
        }
        return (
          <Form.Control as="select" {...props}>
            <option />
            {optionsArray.map(({ value, text }) => (
              <option key={value} value={value}>
                {text}
              </option>
            ))}
          </Form.Control>
        );
      }
      case "radio": {
        const val = props.value;
        delete props.value;
        delete props.placeholder;
        const { options } = extra;

        return options.map((option, index) => {
          return (
            <Form.Check
              key={option.value}
              type="radio"
              inline
              label={option.text}
              value={option.value}
              checked={val && option.value === val ? true : false}
              {...props}
            />
          );
        });
      }
      case "editor": {
        const { name, onChange, onBlur } = props;
        return (
          <Editor
            init={{
              skin_url: "https://unpkg.com/tinymce@5.0.3/skins/ui/oxide",
              content_css:
                "https://unpkg.com/tinymce@5.0.3/skins/content/default/content.css",
              plugins: 'link',
              default_link_target: '_blank',
              link_context_toolbar: true
            }}
            initialValue={this.props.job[name]}
            onChange={(e) => {
              return onChange({ target: { name, value: e.target.getContent() } })
              }
            }
            onBlur={onBlur}
          />
        );
      }
      case "autocomplete": {
        //TODO adapt this to be used by more than one field
        return (
          <Autocomplete
            getItemValue={item => item.value}
            items={this.state.city
              ? this.state.cities.filter(x => x.text.startsWith(this.state.city))
              : this.state.cities
            }
            renderItem={(item, isHighlighted) =>
              <div key={item.value} style={{background: isHighlighted ? 'lightgray' : 'white'}}>
                {item.text}
              </div>
            }
            renderInput={inputProps =>
              <>
                <Form.Control {...inputProps}
                            name={props.name}
                            type="text" autoComplete="new-password" isInvalid={props.isInvalid}
                            onBlur={e => {
                              e.persist()
                              props.onBlur(e)
                              inputProps.onBlur(e)
                            }} />
                <Form.Control.Feedback type="invalid">
                  {validationError}
                </Form.Control.Feedback>
              </>
            }
            renderMenu={(items, value, style) =>
              <div style={{ ...style, ...autoCompleteMenuStyle, maxHeight: `calc(100vmin - ${style.top}px)` }}
                   children={items}
              />
            }
            value={this.state.city}
            onChange={props.onChange}
            onSelect={value =>
              props.onChange({target: {name: props.name, value}})
            }
            wrapperStyle={{
              display: 'block'
            }}
          />
        );
      }
      case "checkbox": {
        const {value, ...checkProps} = props;
        return (
          <Form.Check
            type="checkbox"
            inline
            label={extra.text}
            checked={value}
            {...checkProps}
          />
        );
      }
      case "custom": {
        const { render } = extra;
        return render(this, props);
      }
      default:
        return null;
    }
  }

  _validateForm = (form, sections) => {
    const validators = sections.reduce((validators, section) => {
      for (const row of section.rows) {
        for (const field of row) {
          validators[field.name] = field.validator;
        }
      }
      return validators;
    }, {});
    const validationErrors = {};
    let errors = 0;
    for (let i = 0; i < form.length; i++) {
      const field = form[i];
      const validator = validators[field.name];
      const error = validator && validator(field.value, this.state);
      if (error) errors++;
      validationErrors[field.name] = error;
    }
    if (errors) this.setState({ validationErrors });
    return errors === 0;
  };

  _postJobDetails = async (e) => {
    if (e) e.preventDefault();
    const form = e ? e.target : document.querySelector('form[name="job-form"]');

    if (this._validateForm(form, jobDetailsForm.sections)) {
      try {
        const { draftJobId: jobId, job }  = this.props;
        const isExpiredOrDeleted = job
          ? job.isExpired || job.isDeleted
          : false;

        const { cities, validationErrors, exclusiveTo, isDirty, isExclusiveDirty, ...post } = this.state;
        post.jobId = isExpiredOrDeleted ? null : jobId;

        await this.props.saveJobPosting(post);
        await this._saveExclusiveTo(post.jobId || this.props.draftJobId);

        this._goBack();
      } catch (e) {
        document.querySelector(".view-content").scrollTo(0, 0);
        console.log(e);
      }
    }
  };

  _saveDraft = async (e) => {
    if (e) e.preventDefault();
    const { cities, validationErrors, exclusiveTo, isDirty, isExclusiveDirty, ...draft } = this.state;
    const { draftJobId: jobId, saveJobPostingDraft } = this.props;
    draft.jobId = jobId;
    try {
      if (isDirty || !jobId) {
        await saveJobPostingDraft(draft);
      }
      if (isExclusiveDirty) {
        await this._saveExclusiveTo(jobId || this.props.draftJobId);
      }
      if (isDirty || !jobId || isExclusiveDirty || e) {
        toast.success("Draft saved successfully");
      }
      this.setState({ isDirty: false, isExclusiveDirty: false });
    } catch (e) {
      window.scrollTo(0, 0);
      console.log(e);
    }
  };

  _saveExclusiveTo = async(jobId) => {
    const { exclusiveTo } = this.state;
    if (Array.isArray(exclusiveTo) && exclusiveTo.length > 0) {
      await this.props.saveJobExclusiveTo({ jobId, recruiterIds: exclusiveTo.map(x => x.value) });
    }
  };

  _findRecruiters = async(inputValue, page) => {
    const {data} = await axios.get(
      `${process.env.PUBLIC_URL}/api/v1/recruiters/find`,
      {
        params: {search: inputValue, forAutocomplete: true, offset: page - 1},
        headers: authorizationHeaders()
      }
    );
    return data.map(r => ({
      value: r.recruiterId,
      label: `${r.name} (${r.email})`,
    }));
  };

  _loadJob = async(jobId) => {
    await this.props.findJob({ jobId });
    const { allowedRecruiterIds, exclusiveTo, ...job } = this.props.job;
    job.salaryDisclosed = !!(job.salaryFrom && job.salaryTo) || job.draft;
    job.exclusiveTo = exclusiveTo && exclusiveTo.map(x => ({ label: `${x.name} ${x.email}`, value: x.recruiterId }));
    this.setState(job);
  };

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      match: {
        params: { jobId },
      },
    } = this.props;
    if (this.props.job && this.props.job.jobId !== prevProps.job.jobId) {
      if (jobId && jobId !== "new") {
        await this._loadJob(jobId);
      }
    }
  }

  async componentDidMount() {
    await this.props.initPage("JobDetails");
    const auth = Auth.getInstance();
    const userId = auth.getUserId();
    this.props.findLoggedEmployer({ employerId: userId });
    const {
      match: {
        params: { jobId },
      },
    } = this.props;
    if (jobId && jobId !== "new") {
      await this._loadJob(jobId);
    } else {
      this.setState({
        locationType: "head-office",
        candidateRelocation: "no",
        jobType: jobTypes[0].value,
        salaryDisclosed: true,
      });
      this.draftSaveInterval = setInterval(() => {
        this._saveDraft();
      }, 120 * 1000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.draftSaveInterval);
  }

  render() {
    const { loggedEmployer } = this.props;
    return this._renderForm({
      ...jobDetailsForm,
      title: `${jobDetailsForm.title} (you have ${
        loggedEmployer.purchasedJobs ? loggedEmployer.purchasedJobs : 0
      } purchased posts left)`,
      onSubmit: this._postJobDetails,
      onCancel: this._goBack,
    });
  }
}

JobDetailsPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,

  draftJobId: PropTypes.string,
  job: PropTypes.object.isRequired,
  loggedEmployer: PropTypes.object.isRequired,

  findLoggedEmployer: PropTypes.func.isRequired,
  findJob: PropTypes.func.isRequired,
  saveJobPostingDraft: PropTypes.func.isRequired,
  saveJobPosting: PropTypes.func.isRequired,
  saveJobExclusiveTo: PropTypes.func.isRequired,
  formJobError: PropTypes.string.isRequired,
  initPage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  draftJobId: state.jobs.draftJobId,
  job: state.jobs.job,
  formJobError: state.jobs.formJobError,
  loggedEmployer: state.employers.loggedEmployer,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  findLoggedEmployer,
  initPage,
  saveJobPostingDraft,
  saveJobPosting,
  saveJobExclusiveTo,
  findJob,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(JobDetailsPage);
